
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import ApiService from "@/core/services/ApiService";
import { ElMessage } from "element-plus";
import AddressTypesSelect from "@/components/catalogs-select/AddressTypesSelect.vue";

export default {
  name: "AddressesTE",
  components: { AddressTypesSelect },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    disable: {
      type: Boolean,
      default: false,
    },
    loadable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["createElement", "removeElement", "fieldSelected", "saveElement"],

  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const loading = ref(false);
    const isFirst = ref(true);
    const dataTable = computed(() => store.getters.Addresses);

    const headers = ref([
      { label: "address_types", prop: "address_types" },
      { label: "istreet", prop: "street" },
      { label: "inumber", prop: "number" },
      { label: "ifloornumber", prop: "floor_number" },
      { label: "idoor", prop: "door" },
      { label: "ilocationid", prop: "location" },
      { label: "izipcode", prop: "zip_code" },
      { label: "ilatitude", prop: "latitude" },
      { label: "ilongitude", prop: "longitude" },
    ]);
    const headerTable = ref(headers.value);
    const selectLocations = (query) => {
      if (query !== "") {
        setTimeout(() => {
          store.commit("setLoading", false);
          ApiService.get(
            `/api/location/lists?name=` + query + `&per_page=10`
          ).then(({ data }) => {
            location.value.list = data.locations;
            location.value.options = data.locations;
          });
          // }
        }, 200);
      }
    };
    // eslint-disable-next-line @typescript-eslint/ban-types
    const handleCommand = (command: string | number | object) => {
      if (command === "a") {
        ElMessage(`click on item ${command}`);
      } else {
        emit("createElement", contactSelected);
      }
    };
    const elements = ref({
      loading: false,
      options: [],
      list: [],
    }) as any;
    const location = ref({
      loading: false,
      options: [],
      list: [],
    }) as any;

    const contactSelected = ref({
      contact_type_id: 1,
      entities: route.params.id,
    });
    watch(
      () => dataTable.value,
      (first, second) => {
        if (first && !isFirst.value) {
          store.commit("setAddresses", first);
        } else {
          isFirst.value = false;
        }
        const location_ids = first.map((x) => x.location_id);
        location_ids.forEach(function (value) {
          console.log("value", first);
          console.log("location.value.options", location.value.options);
          console.log("location.value.options", value);
          if (!location.value.options.includes(value) && value != undefined) {
            ApiService.get("/api/location/" + value).then(function (response) {
              location.value.options.push(response);
            });
          }
        });
      },
      { deep: true }
    );
    onMounted(() => {
      /*ApiService.get("/api/address").then(function (response) {
        elements.value.options = response.data.address;
      });*/
      ApiService.get("/api/location/lists").then(function (response) {
        location.value.options = response.data.locations;
      });
    });

    return {
      selectLocations,
      elements,
      location,
      dataTable,
      loading,
      headers,
      headerTable,
      contactSelected,
      handleCommand,
    };
  },
};
