
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "../../core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "AddressTypesSelect",
  props: {
    items: {
      type: Array,
      default: undefined,
    },
    address_type_id: {
      type: [Number, Array],
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:address_type_id"],

  setup(props) {
    const addressTypeSelect = computed(() => store.getters.AddressType);
    const selected = ref(props.address_type_id) as any;
    const { t } = useI18n();
    const store = useStore();
    const inputAddressType = ref({
      loading: false,
      options: [],
      list: [],
    });
    onMounted(function () {
      if (props.items != undefined) {
        props.items.forEach(function (value) {
          if (value.id) {
            selected.value.push(value.id);
          }
        });
      }
    });

    const isAddressRequired = (value) => {
      if (props.required && !props.multiselect && !value) {
        return t("raddresstype");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "type_of_address",
      isAddressRequired
    );

    const getAddressType = () => {
      store.commit("setLoading", false);
      if (addressTypeSelect.value.length === 0) {
        ApiService.get("/api/address-types/lists").then(({ data }) => {
          inputAddressType.value.list = data.address_types;
          inputAddressType.value.options = data.address_types;
          store.commit("setAddressType", data.address_types);
        });
      } else {
        inputAddressType.value.list = addressTypeSelect.value;
        inputAddressType.value.options = addressTypeSelect.value;
      }
    };
    const selectAddressType = (query) => {
      if (query !== "") {
        store.commit("setLoading", false);
        ApiService.query(`/api/address-types/lists`, {
          params: { per_page: 25, name: query },
        }).then(({ data }) => {
          data.address_types.forEach((item) => {
            store.commit("addAddressType", item);
          });
          inputAddressType.value.list = addressTypeSelect.value;
          inputAddressType.value.options = data.address_types;
        });
      } else {
        inputAddressType.value.options = [];
      }
    };

    watch(
      () => props.address_type_id,
      (first) => {
        if (first != undefined) {
          element_id.value = first;
        }
      }
    );

    watch(
      () => props.items,
      (first) => {
        if (first) {
          first.forEach(function (value) {
            if(value.id){
              selected.value.push(value.id);
            }
          });
        }
      }
    );

    onMounted(() => getAddressType());

    return {
      element_id,
      selected,
      inputAddressType,
      errorMessage,
      getAddressType,
      selectAddressType,
    };
  },
};
